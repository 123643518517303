// import '../libs/tilt';
// import Swiper from "../libs/swiper";

import IntlTelInput from "../libs/intl-tel-input";
import AOS from "../libs/aos";
import Maps from "./maps/index";

import "../libs/chosen/chosen";

export default new (class App {
  constructor() {
    this.setDomMap();
    this.previousScroll = 0;

    // dom ready shorthand
    $(() => {
      this.domReady();
    });
  }

  domReady = () => {
    (this.window_width = $(window).width()),
      (this.window_height = $(window).height()),
      (this.isMobile = false),
      (this.isSafari = false),
      (this.isLoaded = false),
      (this.ie = false);

    // Check Device
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge|maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.isMobile = true;
    }

    if (this.isMobile == true) {
      $("body").addClass("isMobile");
    } else {
      $("body").addClass("isDesktop");
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > 0
    ) {
      this.ie = true;
      $("html").addClass("ie10");
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.ie = true;
      $("html").addClass("ieEdge");
    }

    // Specifically for IE8 (for replacing svg with png images)
    // if ($('html').hasClass('ie8')) {
    //
    // }

    this.handleSplashScreen();
    this.initComponents();
    // this.handleUserAgent();
    this.bindEvents();
    this.setAos();
    this.intlInput();
    this.windowResize();
    if (window.location.hash !== "") {
      console.log("hash link");
      var hasid = window.location.hash.replace("#!", "");
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop: $(hasid).offset().top,
          },
          800
        );
      }, 1500);
    }
  };

  homeSliders = () => {
    let establishmentLaw = new Swiper(".swiper-container.establishment_law", {
      slidesPerView: 1,
      spaceBetween: 15,
      loop: true,
      updateOnWindowResize: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      // autoplay: {
      //   delay: 5000,
      // },
    });

    new Swiper(".swiper-container.events_news", {
      slidesPerView: 1,
      spaceBetween: 15,
      updateOnWindowResize: true,
      // effect: 'fade',
      // fadeEffect: {
      //   crossFade: true
      // },
      navigation: {
        nextEl: ".slide-nav .swiper-next",
        prevEl: ".slide-nav .swiper-prev",
      },
      loop: true,
    });

    let vision2 = new Swiper(".swiper-container.vision-2", {
      slidesPerView: 1,
      spaceBetween: 15,
      allowTouchMove: false,
      loop: false,
      loopedSlides: 2,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
      },
    });
    let vision1 = new Swiper(".swiper-container.vision-1", {
      slidesPerView: 1,
      spaceBetween: 15,
      loop: false,
      allowTouchMove: false,
      loopedSlides: 2,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 5000,
      },
    });
    // let target = $('.vision-1 .swiper-slide.swiper-slide-active .text-splitting');
    // Splitting({ target: target, by: 'words' });
    //

    vision1.on("slideChangeTransitionStart", function () {
      console.log('transition start');
      $(
        ".vision-1 .swiper-slide.swiper-slide-active .text-splitting"
      ).removeClass("splitting");
      $(
        ".vision-2 .swiper-slide.swiper-slide-active .text-splitting"
      ).removeClass("splitting");
    });

    vision1.on("slideChangeTransitionEnd", function () {
      console.log('transition end');
      $(".vision-1 .swiper-slide.swiper-slide-active .text-splitting").addClass(
        "splitting"
      );
      $(".vision-2 .swiper-slide.swiper-slide-active .text-splitting").addClass(
        "splitting"
      );
    });

    establishmentLaw.on("slideChangeTransitionStart", function () {
      $(
        ".establishment_law .swiper-slide.swiper-slide-active .text-splitting"
      ).removeClass("splitting");
    });

    establishmentLaw.on("slideChangeTransitionEnd", function () {
      $(
        ".establishment_law .swiper-slide.swiper-slide-active .text-splitting"
      ).addClass("splitting");
    });
  };

  initComponents = () => {
    //  CAll PAGE WISE JS HERE
    if ($(".home-page").length) {
      this.homeSliders();

      // this.heightCalculate();
    }

    if ($(".news-event-detail-page").length) {
      new Swiper(".swiper-container.gallery-preview-sider", {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: false,
        breakpoints: {
          640: {
            slidesPerView: 1.4,
            spaceBetween: 20,
            centeredSlides: true,
          },
        },
      });
      new Swiper(".swiper-container.gallery-sider", {
        slidesPerView: 1.4,
        centeredSlides: true,
        spaceBetween: 60,
        loop: false,
        breakpoints: {
          640: {
            slidesPerView: 1.2,
            spaceBetween: 20,
            centeredSlides: true,
          },
        },
      });
      new Swiper(".swiper-container.events_news", {
        slidesPerView: 1,
        spaceBetween: 15,
        updateOnWindowResize: true,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        navigation: {
          nextEl: ".slide-nav .swiper-next",
          prevEl: ".slide-nav .swiper-prev",
        },
        loop: true,
      });

      new Swiper(".swiper-container.services-slider", {
        slidesPerView: 1,
        spaceBetween: 15,
        loop: true,
        updateOnWindowResize: true,
        // effect: 'fade',
        // fadeEffect: {
        //   crossFade: true
        // },
        autoplay: {
          delay: 4000,
        },
      });
    }

    if ($(".who-we-are-page").length) {
      let verticalSlider = new Swiper(".swiper-container.tabs-container-v", {
        direction: "horizontal",
        allowTouchMove: false,
        spaceBetween: 15,
      });
      new Swiper(".swiper-container.tabs-container-h1", {
        spaceBetween: 15,
      });
      new Swiper(".swiper-container.tabs-container-h2", {
        spaceBetween: 15,
      });
    }

    if ($(".news-event-list-page").length) {
      $.ajax({
        type: "GET",
        url: "https://www.juicer.io/api/feeds/faa-dubai?per=10&is_on_feeds_dashboard=true&page=1",
        success: (data) => {
          let postsList = [];
          postsList = data.posts.items;
          this.getPosts(postsList);
          new Swiper(".insta-slider", {
            slidesPerView: 1,
            spaceBetween: 15,
            autoplay: {
              delay: 2000,
            },
            pagination: {
              el: ".insta-slider .swiper-pagination",
              clickable: true,
            },
          });
          return postsList;
        },
        dataType: "jsonp",
      });
    }

    if ($("#map_canvas").length) {
      this.mapContainer = $("#map_canvas");
      new Maps({
        mapContainer: this.mapContainer,
      });
    }

    if ($(".who-we-are-page").length) {
      $('.main-tabs a[href*="#"]').on("click", function (e) {
        e.preventDefault();
        var arr = $(this).attr("href").split("#");
        if (arr.length > 1) {
          $("html, body").animate(
            {
              scrollTop: $("#" + arr[1]).offset().top,
            },
            500,
            "linear"
          );
        }
      });
    }

    if ($(".flag-an-issue").length) {
      this.intlInput(".flag-an-issue .tel-flagPop");
    }
    if ($(".content-page").length) {
      this.intlInput(".content-page .tel-flagPop");
    }
    if ($(".tel-contact").length) {
      this.intlInput(".tel-contact");
    }

    if ($(".body-wrapper.content-page.whistle-blower,.contact_page").length) {
      // $('body').addClass('law-search');
      $(document).ready(function () {
        if ($(window).width() > 1200) {
          if ($(".whistle-blower .form-side select,.contact_page select").length) {
            console.log('iside');
            $("select").chosen({
              disable_search_threshold: 3,
              search_contains: true,
            });

            // Click outside close selectbox
            $(document).mouseup(function (e) {
              var selectContainer = $(".chosen-container");

              if (!selectContainer.is(e.target) && selectContainer.has(e.target).length === 0) {
                // Clicked outside the element
                $('.chosen-container').removeClass('chosen-container-active chosen-with-drop');
                console.log('class removed');
              }
             });

            $('.otpLink').on('keydown', function (e) {
              if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                $('.otp-popUp').addClass('active');
                $('body').addClass('popup-open');
                setTimeout(() => {
                  $('.otp-boxes input').eq(0).focus();
                }, 300);
                console.log('opt clicked');
              }
            });

            $('#file1btn').on('keydown', function (e) {
              if (e.which === 13 || e.which === 32) {
                e.preventDefault();
                $('#file1btn').trigger('click');
                console.log('button clicked');
              }
            });

            $(document).bind('keydown', function(e) {
               if (e.which === 27) {
                if($('.otp-popUp').hasClass('active')){
                  $('.otp-popUp').removeClass('active');
                  $('body').removeClass('popup-open');
                }
              }
            });

            if($('.complainants_details').length){
              $('.complainants_details .voilation_reported input[type="radio"]').prop('tabindex',0);
            }

            if($('#contactForm,.contact_page').length){
              $('#contactForm .js-focus select,.contact_page .js-focus').prop('tabindex',0);
            }

               $(document).on('focus','.js-focus', function (e) {
                var $that = $(this);
                setTimeout(function () {
                  if ($that.hasClass('focus')) { // Check for Enter key and 'focus' class
                    // Trigger the Chosen dropdown (you should adjust the selector accordingly)
                    e.preventDefault();
                    console.log($('.chosen-container'));
                    $('html, body').animate({ scrollTop: $that.offset().top - 200 }, 50);


                    $that.find('.chosen-container .chosen-single').trigger('mousedown');
                    // var $that = $(this);
                    setTimeout(() => {
                      $that.find('.chosen-container').addClass('chosen-container-active chosen-with-drop');
                      // $(this).find('.chosen-container').addClass('');
                      $that.find('.chosen-container .chosen-results li').prop('tabindex', 1);
                    }, 100)
                    console.log('chosen int');
                  }
                }, 500)
              });

            setTimeout(function () {
              // $('.js-focus').prop('tabindex', 1);

              $('.js-focus').on('focus', function () {
                $(this).addClass('focus');
              });




              $('.js-focus').on('blur', function (e) {
                $(this).removeClass('focus');
              });

            }, 500)
          }
        } else {
          if (
            $(".whistle-blower .form-side select").length &&
            $("select").length
          ) {
            $("select").each(function () {
              $(this).wrap('<div class="select-container"></div>');
            });
          }
        }

        $("body.popup-open .popup-cover, #close-otp-popup").on("click", () => {
          $(".otp-popUp").removeClass("active");
          $('.btn-prev').focus();
          $("body").removeClass("popup-open");
        });

        var phoneNumberInput = $(".step_2 .phone-field input");

        // Set cursor position after the country code on input field click


        // Set cursor position after the country code on input field focus
        phoneNumberInput.on("click", function () {
          // Set the cursor position after the country code
          setCursorPosition(phoneNumberInput[0], 3);
        });

        // Function to set the cursor position
        function setCursorPosition(element, position) {
          if (element.setSelectionRange) {
            element.setSelectionRange(position, position);
          } else if (element.createTextRange) {
            var range = element.createTextRange();
            range.collapse(true);
            range.moveEnd('character', position);
            range.moveStart('character', position);
            range.select();
          }
        }

        $('.otp-boxes input[type="text"]').on("keydown", function (e) {
          var key = e.keyCode || e.which;
          var maxLength = $(this).attr("maxlength");
          var length = $(this).val().length;

          // Allow numerical values (0-9) and num lock numbers (numpad 0-9)
          if ((key < 48 || key > 57) && (key < 96 || key > 105)) {
            // Check for backspace key
            if (key !== 8) {
              e.preventDefault();
              return;
            }
          }

          // Move to the next input field once a value is added
          if (length >= maxLength) {
            var nextInputId = $(this).data("next");
            $("#" + nextInputId).focus();
          }

          // Move to the previous input field on backspace
          if (key === 8 && length === 0) {
            var prevInputId = $(this).data("previous");
            if (prevInputId) {
              $("#" + prevInputId).focus();
            }
          }
        });

        var checkBox = false;

        $('.step_1 .terms_and_conditions input[type="checkbox"]').change(
          function () {
            if ($(this).is(":checked")) {
              checkBox = true;
              $(".btn-next").removeClass("disabled");
            } else {
              $(".btn-next").addClass("disabled");
              checkBox = false;
            }
            console.log(checkBox)
          }
        );

        var checkBox2 = false;
        $('.preview .terms_and_conditions input[type="checkbox"]').change(
          function () {
            if ($(this).is(":checked")) {
              checkBox2 = true;
              $(".btn-red").removeClass("disabled");
            } else {
              $(".btn-red").addClass("disabled");
              checkBox2 = false;
            }
          }
        );

        $("#user_num input").on("keydown", function (event) {
          if ($(this).val().length === 3 && event.keyCode === 8) {
            event.preventDefault();
          }
        });

        $(".btn-next").click(function (e) {
          e.preventDefault();
          // if ($(".step_1").hasClass("active")) {
          if (!$(this).hasClass("disabled")) {
            console.log("nxt");
            setTimeout(() => {
              $('.steps.active input').eq(0).focus();
            }, 200);

            $("html, body").animate({ scrollTop: 0 }, "slow");

            if ($(".step_1").hasClass("active")) {
              $(".step_1").removeClass("active");
              $(".step_2").addClass("active");
              $(".btn-prev").removeClass("hide");

              setTimeout(function () {
                $(".btn-next").addClass("disabled");
              }, 1000);
            } else if ($(".step_2").hasClass("active")) {
              $(".step_2").removeClass("active");
              $(".step_3").addClass("active");

              $(".btn-next").text("Preview");
              // $(".btn-prev").removeClass("hide");
            } else if ($(".step_3").hasClass("active")) {
              $(".step_3").removeClass("active");
              $(".preview").addClass("active");
              $(".submit").removeClass("hide");
              $(".btn-next").addClass("hide");

              setTimeout(function () {
                var formData = {};

                formData.name = $(".personal_details .name input ").val();

                if (
                  $(".personal_details .role select").val() === "Other" ||
                  $(".personal_details .role select").val() === "أخرى"
                ) {
                  formData.role = $(
                    ".personal_details .roleTextWrapper input"
                  ).val();
                } else {
                  formData.role = $(".personal_details .role select").val();
                }

                formData.email = $(".personal_details .email input ").val();
                formData.phone = $(
                  ".personal_details .phone-field input"
                ).val();

                if (
                  $(".complainants_details .emirates select").val() ===
                  "Other" ||
                  $(".complainants_details .emirates select").val() === "أخرى"
                ) {
                  formData.emirates = $(
                    ".complainants_details .emiratesTextWrapper  input"
                  ).val();
                } else {
                  formData.emirates = $(
                    ".complainants_details .emirates select"
                  ).val();
                }

                formData.entityName = $(
                  ".complainants_details .entity_name input"
                ).val();

                if (
                  $(".complainants_details .voilation_type select").val() ===
                  "Other" ||
                  $(".complainants_details .voilation_type select").val() ===
                  "أخرى"
                ) {
                  formData.voilationType = $(
                    ".complainants_details .voilationTypeTextWrapper input"
                  ).val();
                } else {
                  formData.voilationType = $(
                    ".complainants_details .voilation_type select"
                  ).val();
                }

                formData.voilationDesc = $(
                  ".complainants_details .voilation_desc textarea"
                ).val();

                formData.dateOfVoilation =
                  $(
                    ".complainants_details .year-months #ctl00_PlaceHolderMain_FlagIssue_ctl00_month_chosen .chosen-single span"
                  ).text() +
                  " " +
                  $(
                    ".complainants_details .year-months #ctl00_PlaceHolderMain_FlagIssue_ctl00_year_chosen .chosen-single span"
                  ).text();

                formData.voilationReported = $(
                  '.complainants_details .voilation_reported input[name="ctl00$PlaceHolderMain$FlagIssue$ctl00$choice"]:checked'
                ).val();

                formData.reportedEnityName = $(
                  ".complainants_details .voilator_name input"
                ).val();

                formData.reportedEnityRefId = $(
                  ".complainants_details .refId input"
                ).val();

                formData.reportedDate =
                  $(
                    ".complainants_details .ref-year-months #ctl00_PlaceHolderMain_FlagIssue_ctl00_ref_month_chosen .chosen-single span"
                  ).text() +
                  " " +
                  $(
                    ".complainants_details .ref-year-months #ctl00_PlaceHolderMain_FlagIssue_ctl00_ref_year_chosen .chosen-single span"
                  ).text();

                formData.additionalDetails = $(
                  ".complainants_details .additional_details textarea"
                ).val();

                console.log(formData, "data");

                setTimeout(function () {
                  $(".preview .personal_name input").val(formData.name);
                  $(".preview .role input").val(formData.role);
                  $(".preview .email input").val(formData.email);
                  $(".preview .phone-field input").val(formData.phone);
                  $(".preview .emirates input").val(formData.emirates);
                  $(".preview .preview_entity_name input").val(
                    formData.entityName
                  );
                  $(".preview .voilation_type input").val(
                    formData.voilationType
                  );
                  $(".preview .voilation_desc input").val(
                    formData.voilationDesc
                  );
                  $(".preview .voilation_date input").val(
                    formData.dateOfVoilation
                  );
                  $(".preview .preview-response-field input").val(
                    formData.voilationReported
                  );
                  $(".preview .voilators_name input").val(
                    formData.reportedEnityName
                  );
                  $(".preview .ref-no input").val(formData.reportedEnityRefId);
                  $(".preview .reported_date input").val(formData.reportedDate);
                  $(".preview .addition_desc input").val(
                    formData.additionalDetails
                  );

                  if ($("body").hasClass("arabic")) {
                    if (formData.voilationReported === "Yes") {
                      $(".preview_voilation_yes_condition").removeClass("hide");
                    } else if (formData.voilationReported === "نعم") {
                      $(".preview_voilation_yes_condition").removeClass("hide");
                    } else {
                      $(".preview_voilation_yes_condition").addClass("hide");
                    }

                    // if (formData.voilationType === "أخرى") {
                    $("#preview_voilation_description").removeClass("hide");
                    // } else {
                    //   $("#preview_voilation_description").addClass("hide");
                    // }
                  } else {
                    if (formData.voilationReported === "Yes") {
                      $(".preview_voilation_yes_condition").removeClass("hide");
                    } else {
                      $(".preview_voilation_yes_condition").addClass("hide");
                    }

                    // if (formData.voilationType === "Other") {
                    $("#preview_voilation_description").removeClass("hide");
                    // } else {
                    //   $("#preview_voilation_description").addClass("hide");
                    // }
                  }
                }, 700);

                var voilators_details = [];

                if ($("#preview_add_options .details").length) {
                  $("#preview_add_options .details").remove();
                }

                var detailsElements = document.querySelectorAll(
                  "#add_options .details"
                );
                for (var i = 0; i < detailsElements.length; i++) {
                  var nameInput =
                    detailsElements[i].querySelector(".name-input");
                  var roleInput = detailsElements[i].querySelector(".persons");

                  var nameValue = nameInput.value;
                  var roleValue = roleInput.value;

                  var details = {
                    nameValue: nameValue,
                    roleValue: roleValue,
                  };

                  voilators_details.push(details);

                  details = {};
                }

                var filtered_voilators_details = voilators_details.filter(
                  function (obj) {
                    return obj.nameValue !== "" && obj.roleValue !== "";
                  }
                );
                // console.log(filtered_voilators_details, "filtered array");
                filtered_voilators_details.forEach(function (vDetails) {
                  var detailDiv = $(
                    '<div class="trow details duplicate-row"></div>'
                  );
                  var col1 = $('<div class="tcol"></div>');
                  var col2 = $('<div class="tcol"></div>');

                  var input1 = $('<input readonly type="text">').val(
                    vDetails.nameValue
                  );
                  var input2 = $('<input readonly type="text">').val(
                    vDetails.roleValue
                  );

                  col1.append(input1);
                  col2.append(input2);

                  detailDiv.append(col1, col2);

                  $("#preview_add_options .body").append(detailDiv);
                });

                var attachments = [];
                var attachmentsDiv = document.querySelectorAll(
                  "#upload-files .upload-file-preview .file__value"
                );
                for (var i = 0; i < attachmentsDiv.length; i++) {
                  var fileName =
                    attachmentsDiv[i].querySelector(".file__value--text");
                  // console.log(fileName);
                  var fileNameVal = fileName.textContent;

                  if (fileNameVal !== "") {
                    attachments.push(fileNameVal);
                  }
                }
                // console.log(attachments, "attachments");

                if (
                  $("#preview_upload-files .upload-file-preview .file__value")
                    .length
                ) {
                  $(
                    "#preview_upload-files .upload-file-preview .file__value"
                  ).remove();
                }
                attachments.forEach(function (attachment) {
                  var file_div = $(
                    "<div class='file__value'><div class='file__value--text'>" +
                    attachment +
                    "</div></div>"
                  );

                  $("#preview_upload-files .upload-file-preview").append(
                    file_div
                  );
                });

                attachments = [];
              }, 1000);
            }
          }
          // }
        });

        $(".btn-prev").click(function (e) {
          e.preventDefault();
          if (!$(this).hasClass("disabled")) {
            console.log("prev");
            setTimeout(() => {
              $('.steps.active input').eq(0).focus();
            }, 200);
            $("html, body").animate({ scrollTop: 0 }, "slow");
            if ($(".preview").hasClass("active")) {
              $(".preview").removeClass("active");
              $(".step_3").addClass("active");
              $(".submit").addClass("hide");
              $(
                "#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue3"
              ).removeClass("hide");
            } else if ($(".step_3").hasClass("active")) {
              $(".step_3").removeClass("active");
              $(".step_2").addClass("active");
              $(".btn-next").text("Next");
              $(
                "#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue2"
              ).removeClass("hide");
              $("#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue3").addClass(
                "hide"
              );
            } else if ($(".step_2").hasClass("active")) {
              $(".step_2").removeClass("active");
              $(".step_1").addClass("active");
              $(".btn-next").text("Next");
              $(this).addClass("hide");
              $("#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue2").addClass(
                "hide"
              );
              $("#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue3").addClass(
                "hide"
              );
              $(
                "#ctl00_PlaceHolderMain_FlagIssue_ctl00_btnContinue1"
              ).removeClass("hide");

              if (checkBox === true) {
                // console.log(checkBox);
                $(".btn-next").removeClass("disabled");
              }
            }
          }
        });

        var monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        $("#year").on("change", function () {
          $("#month").empty();

          // Add all months as options
          for (var i = 0; i < 12; i++) {
            var option = $("<option>")
              .text(monthNames[i])
              .val(i + 1);
            $("#month").append(option);
          }
        });

        $("#ref-year").on("change", function () {
          $("#ref-month").empty();

          // Add all months as options
          for (var i = 0; i < 12; i++) {
            var option = $("<option>")
              .text(monthNames[i])
              .val(i + 1);
            $("#ref-month").append(option);
          }
        });

        $('.voilation_reported input[type="radio"]').change(function () {
          var selectedValue = $(this).val();

          if (selectedValue === "Yes") {
            $("#voilation_yes_condition").show();
          } else if (selectedValue === "No") {
            $("#voilation_yes_condition").hide();
          }
        });

        $(".voilation_type select").change(function () {
          var selectedValue = $(this).val();
          if (selectedValue === "Other" || selectedValue === "أخرى") {
            $(".voilationTypeTextWrapper").removeClass("hide");
          } else {
            $(".voilationTypeTextWrapper").addClass("hide");
          }
        });

        $(".emirates select").change(function () {
          var selectedValue = $(this).val();
          if (selectedValue === "Other" || selectedValue === "أخرى") {
            $(".emiratesTextWrapper").removeClass("hide");
          } else {
            $(".emiratesTextWrapper").addClass("hide");
          }
        });

        $(".role select").change(function () {
          var selectedValue = $(this).val();
          if (selectedValue === "Other" || selectedValue === "أخرى") {
            $(".roleTextWrapper").removeClass("hide");
          } else {
            $(".roleTextWrapper").addClass("hide");
          }
        });

        $("#add_options").on("click", ".add-fields input", function (e) {
          e.preventDefault();
          var duplicateRow = $(".duplicate-row:last").clone(); // Clone the first .duplicate-row div
          duplicateRow.find("input").val(""); // Clear the input value in the cloned div
          duplicateRow.find("select").val(""); // Clear the select value in the cloned div
          duplicateRow.insertAfter(".duplicate-row:last");

          var selectField = duplicateRow.find("select");

          var chosenContainer = duplicateRow.find(".chosen-container");
          chosenContainer.remove();

          setTimeout(function () {
            if ($(window).width() > 1200) {
              if ($(selectField).length) {
                $(selectField).chosen({
                  disable_search_threshold: 3,
                  search_contains: true,
                });
              }
            }
          }, 200);
        });

        $("#add_options").on("click", ".close-field", function () {
          var $row = $(this).closest(".duplicate-row"); // Get the closest parent row with class .duplicate-row
          $row.remove(); // Remove the row from the DOM
        });

        $("#upload-files").on("change", "#FileUpload", function (event) {
          $(".upload-file-preview").empty();

          var files = event.target.files;

          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            $(
              "<div class='file__value'><svg width='16' height='19' viewBox='0 0 16 19' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5.76492 13.6726L13.9568 5.31229C14.3968 4.83427 14.6414 4.19163 14.6376 3.52368C14.6338 2.85572 14.3819 2.21625 13.9366 1.74386C13.4912 1.27148 12.8882 1.00423 12.2583 1.00005C11.6284 0.995868 11.0223 1.25509 10.5714 1.72152L2.17864 10.5951C1.41925 11.4136 0.995625 12.5171 1.00003 13.6653C1.00444 14.8135 1.43653 15.9133 2.20217 16.7252C2.96782 17.5371 4.00499 17.9953 5.08777 18C6.17054 18.0046 7.2112 17.5554 7.98305 16.7502L15 9.32017' stroke='#D9D9D9' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg><div class='file__value--text'>" +
              file.name +
              "</div><div class='file__value--remove' data-id='" +
              file.name +
              "' ></div></div>"
            ).appendTo(".upload-file-preview");
          }
        });

        $("#upload-files").on(
          "click",
          ".upload-file-preview .file__value .file__value--remove",
          function () {
            $(this).parent(".file__value").remove();
          }
        );

        $(".popup-cover").on("click", function () {
          $("#otp-popUp").removeClass("active");
        });
      });
      //
    }
    setTimeout(() => {
      if ($('.body-wrapper .law-search-body,.body-wrapper.content-page.whistle-blower').length) {
        $('body').addClass('law-search');
      }
    }, 1000);
  };

  getPosts = (postsList) => {
    for (let i = 0; i < postsList.length; i++) {
      const url = postsList[i].full_url;
      const image = postsList[i].image;
      const message = postsList[i].unformatted_message;
      if (image) {
        const contentString = `
                        <div class="swiper-slide">
                            <div class="bg-wrapper">
                                 <div class="bg-img" style="background-image: url('${image}')"></div>
                            </div>
                         </div>
                 `;
        $(".insta-slider .swiper-wrapper").append(contentString);
      }
    }
  };

  tiltTrigger = () => {
    $(".js-tilt-glare").remove();
    $(".tilt").tilt({
      maxTilt: 10,
      perspective: 900,
      easing: "cubic-bezier(.03,.98,.52,.99)",
      speed: 400,
      glare: true,
      maxGlare: 0.2,
      scale: 1.05,
    });
    $(".tilt")
      .mouseover(function () {
        console.log("hover working");
        $(this).css("z-index", "5");
      })
      .mouseout(function () {
        $(this).css("z-index", "0");
      });
  };

  destroyTilt = () => {
    this.tilt = $(".tilt");
    this.tilt.tilt.destroy.call(this.tilt);
    $(".js-tilt-glare").remove();
  };

  setAos = () => {
    if (this.isMobile == false || this.ie == false) {
      this.tiltTrigger();

      $(".news-tilt").tilt({
        maxTilt: 5,
        perspective: 900,
        easing: "cubic-bezier(.03,.98,.52,.99)",
        speed: 400,
        glare: false,
        scale: 1.03,
      });
      $(".news-tilt")
        .mouseover(function () {
          console.log("hover working");
          $(this).css("z-index", "5");
        })
        .mouseout(function () {
          $(this).css("z-index", "0");
        });
    }

    if ($(".bg-video").length) {
      let bannervideo = $(".bg-video").find("video");
      bannervideo[0].pause();
    }

    let target = $(".text-splitting");
    setTimeout(function () {
      Splitting({ target: target, by: "words" });
    }, 1500);

    setTimeout(function () {
      AOS.init({
        once: true,
        disable: "mobile",
        delay: 300,
      });
    }, 900);
  };

  handleSplashScreen = () => {
    /* loading screen */
    $(".spinner").fadeIn(500);

    setTimeout(() => {
      $(".loader").fadeOut(900);
    }, 2000);
  };

  setDomMap = () => {
    this.window = $(window);
    this.htmlNbody = $("body, html");
    this.html = $("html");
    this.htmlBody = $("body");
    this.favtrigger = $(".fav-trigger");
    this.searchtrigger = $(".search-trigger");
    this.favtriggerActive = $(".fav-trigger.active");
    this.favpop = $(".fav-pop");
    this.searchpop = $(".search-block-popup");
    this.popupopen = $(".popup-cover");
    this.popupclose = $(".popup-close-btn");
    this.menu = $(".menu");
    this.flagissue = $(".flag-issue");
    this.menuslide = $(".menu-slide");
    this.menuClose = $(".menu-close");
    this.flaganissue = $(".flag-an-issue");
    this.flagClose = $(".flag-an-issue .flag-close");
    this.copyToClipbboard = $(".copy-to");
    this.accordionItem = $(".accordion-item-heading");
    this.accordionCollapse = $(".accordion-collapse");
    this.contrast = $(".contrast");

    this.fontSmall = $(".font-small");
    this.fontBig = $(".font-big");
    this.favThis = $(".fav-this");

    this.tabTrigger = $(".tab-pill");
    this.tabs = $(".tabs");
    this.tabsEnablers = $(".strategic-enablers");
    this.tabsPillers = $(".strategi-pillers");
    this.tabsNav = $(".tabs-nav ul li");

    this.videoToggle = $(".video-toggle");

    this.videoContainer = $(".bg-video video");

    this.gotoTop = $("#gotoTop");
  };

  intlInput = (selector) => {
    this.telInputs = $(selector)[0];
    this.telInputsCheck = $(selector);

    if (this.telInputsCheck.length) {
      const intel = IntlTelInput(this.telInputs, {
        onlyCountries: ["ae"],
        formatOnDisplay: true,
      });
      const countryData = intel.getSelectedCountryData();
      this.telInputsCheck.val(countryData.dialCode + " ");

      this.telInputsCheck.keypress((e) => {
        // Check if the key pressed is a number or backspace key
        if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
          e.preventDefault(); // Prevent the key press event
          return false;
        }
      });

      this.telInputsCheck.on("change", function () {
        const inputVal = $(this).val();

        if (!/^\d+$/.test(inputVal)) {
          const countryData = intel.getSelectedCountryData();
          $(this).val(countryData.dialCode);
        }
      });

      this.telInputs.addEventListener("countrychange", () => {
        const countryData = intel.getSelectedCountryData();
        $(selector).val(countryData.dialCode);
        // do something with iti.getSelectedCountryData()
      });
      this.telInputsCheck.on("paste", (e) => {
        const pastedData = e.originalEvent.clipboardData.getData("text");

        if (!/^\d+$/.test(pastedData)) {
          e.preventDefault();
          // do not perform the paste action
          return;
        }
      });
    }
  };

  bindEvents = () => {
    // this.accordionItem.on('click', (e) => {
    //   var $this = $(e.currentTarget);
    //   this.accordionItem.stop().removeClass('active');
    //   this.accordionCollapse.stop().slideUp().removeClass('active');
    //   $this.addClass('active');
    //   $this.closest('.accordion-item').find('.accordion-collapse').stop().slideToggle().toggleClass('active');
    //
    // });
    this.window.resize(this.windowResize).scroll(this.windowScroll);
    this.tabTrigger.on("click", (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      this.tabTrigger.removeClass("active");
      $($this).addClass("active");
      let trigger = $($this).data("tabtrigger");

      switch (trigger) {
        case "pillers":
          this.tabs.removeClass("is-active");
          this.tabsPillers.addClass("is-active");

          break;
        case "enablers":
          this.tabs.removeClass("is-active");
          this.tabsEnablers.addClass("is-active");

          break;
        default:
          break;
      }
    });

    this.tabsNav.on("click", (e) => {
      var $this = $(e.currentTarget);
      // this.tabsNav.removeClass('is-active');

      var tabsContainer = $($this).closest(".tabs");
      var tabsNav = $(tabsContainer)
        .children(".tabs-nav")
        .children("ul")
        .children("li");
      $(tabsNav).removeClass("is-active");
      $($this).addClass("is-active");
      var tabsContent = $(tabsContainer)
        .children(".tabs-content")
        .children(".tab-card");
      let tabIndex = $($this).index();
      $(tabsContent).removeClass("is-active");
      $(tabsContent).eq(tabIndex).addClass("is-active");
    });
    this.favtrigger.on("click", (e) => {
      var $this = $(e.currentTarget);
      if ($this.hasClass("active")) {
        this.closePopup();
        return;
      }
      e.preventDefault();
      $this.addClass("active");

      this.htmlBody.addClass("popup-open favorite");
      this.favpop.addClass("active");

      new Swiper(".swiper-container.fav-list-wrapper", {
        navigation: {
          nextEl: ".fav-nav .fav-nav-next",
          prevEl: ".fav-nav .fav-nav-prev",
        },
        slidesPerView: "auto",
        spaceBetween: 20,
        breakpoints: {
          640: {
            slidesPerView: 1.1,
            spaceBetween: 20,
            centeredSlides: false,
          },
          767: {
            slidesPerView: "auto",
            spaceBetween: 20,
            centeredSlides: false,
          },
        },
      });
    });
    this.popupopen.on("click", () => {
      this.closePopup();
    });
    this.searchtrigger.on("click", (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      if ($this.hasClass("active")) {
        this.closePopup();
        return;
      }

      $this.addClass("active");

      this.htmlBody.addClass("popup-open search");
      this.searchpop.addClass("active");

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const modal = document.querySelector(".search-block-popup ");

      const firstFocusableElement =
        modal.querySelectorAll(focusableElements)[0];
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];

      document.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      });
      setTimeout(function () {
        firstFocusableElement.focus();
      }, 500);
    });
    this.popupclose.on("click", (e) => {
      this.closePopup();
      $(".search-trigger").focus();
    });
    this.menu.on("click", (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      if ($this.hasClass("active")) {
        this.closeMenu();
        return;
      }

      $this.addClass("active");

      this.htmlBody.addClass("menu-open");
      this.menuslide.addClass("active");

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const modal = document.querySelector(".menu-slide");
      const firstFocusableElement =
        modal.querySelectorAll(focusableElements)[0];
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];
      document.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
        if (!isTabPressed) {
          return;
        }
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      });
      setTimeout(function () {
        firstFocusableElement.focus();
      }, 500);
    });

    this.menuClose.on("click", (e) => {
      e.preventDefault();
      this.closeMenu();
      $(".menu").focus();
    });

    $('.menu-close').on('focus', function () {
      $(this).addClass('focus');
    });


    $('.menu-close').on('keydown',  function(e) {
      if (e.which === 13 && $(this).hasClass('focus')) { // Check for Enter key and 'focus' class
        // Trigger the Chosen dropdown (you should adjust the selector accordingly)
        // this.closeMenu();

        setTimeout(() => {
         $('body').removeClass("menu-open");
         $(".menu").removeClass("active");
        $(".menu-slide").removeClass("active");
        }, 400);
        // $(".menu").focus();
        console.log('menu closed int');
      }
    });

    this.flagissue.on("click", (e) => {
      e.preventDefault();
      var $this = $(e.currentTarget);
      if ($this.hasClass("active")) {
        this.closeFlagIssue();
        return;
      }


      this.clearflagForm();
      this.intlInput(".flag-an-issue .tel-flagPop");

      $this.addClass("active");

      this.htmlBody.addClass("menu-open");
      this.flaganissue.addClass("active");

      const focusableElements =
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
      const modal = document.querySelector(".flag-an-issue");
      const firstFocusableElement =
        modal.querySelectorAll(focusableElements)[0];
      const focusableContent = modal.querySelectorAll(focusableElements);
      const lastFocusableElement =
        focusableContent[focusableContent.length - 1];
      document.addEventListener("keydown", function (e) {
        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
        if (!isTabPressed) {
          return;
        }
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElement) {
            lastFocusableElement.focus();
            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          if (document.activeElement === lastFocusableElement) {
            firstFocusableElement.focus();
            e.preventDefault();
          }
        }
      });
      setTimeout(function () {
        firstFocusableElement.focus();
      }, 500);
    });
    this.flagClose.on("click", (e) => {
      this.closeFlagIssue();
      $(".flag-issue").focus();
    });
    // this.copyToClipbboard.on('click', (e) => {
    //   console.log('clicked copy ');
    //   e.preventDefault();
    //   var $this = $(e.currentTarget);
    //   let copytext = $this.attr('href');
    //
    //   var $temp = $("<input>");
    //   $("body").append($temp);
    //   $temp.val(copytext).select();
    //   document.execCommand("copy");
    //   $this.find('.tooltip').text("Copied!");
    //   $temp.remove();
    // });
    // this.copyToClipbboard.on('mouseout', (e) => {
    //   var $this = $(e.currentTarget);
    //   $this.find('.tooltip').text("Copy to clipboard");
    // });

    this.contrast.on("click", (e) => {
      e.preventDefault();
      this.htmlBody.toggleClass("dark-mode");
    });

    this.fontSmall.on("click", (e) => {
      e.preventDefault();
      if (this.html.hasClass("font-big")) {
        this.html.removeClass("font-big");
      } else {
        this.html.addClass("font-small");
      }
      // this.heightCalculate();
    });
    this.fontBig.on("click", (e) => {
      e.preventDefault();
      if (this.html.hasClass("font-small")) {
        this.html.removeClass("font-small");
      } else {
        this.html.addClass("font-big");
      }
      // this.heightCalculate();
    });

    $("section.menu-slide .link-col ").on("click", "a", (e) => {
      let isTheSameUrl = false;
      const $this = $(e.currentTarget);
      const hasHash = $this.attr("href").indexOf("#") >= 0;

      var arr = $this.attr("href").split("#");
      if (arr.length > 1) {
        console.log("if");
        console.log(arr);
        if (arr[0] === window.location.pathname) {
          isTheSameUrl = arr[0] === window.location.pathname;
        } else {
          window.location.href = $this.attr("href");
        }
      } else if (window.location.pathname === $this.attr("href")) {
        e.preventDefault();
        console.log("else if");
        console.log(arr);
        this.closeMenu();
      } else {
        console.log("else");
        console.log(arr);
      }

      if (hasHash && isTheSameUrl) {
        console.log("hasHash && isTheSameUrl");
        let sectionid = $this[0].hash;
        e.preventDefault();
        this.closeMenu();
        //   close the menu
        setTimeout(function () {
          $("html, body").animate(
            {
              scrollTop: $(sectionid).offset().top,
            },
            500,
            "linear"
          );
        }, 300);
      }
    });

    this.videoToggle.on("click", () => {
      if ($(".bg-video video").get(0).paused) {
        $(".bg-video video").get(0).play();
        this.videoToggle.addClass("active");
      } else {
        $(".bg-video video").get(0).pause();
        this.videoToggle.removeClass("active");
      }
    });
  };
  clearflagForm = () => {
    $('[data-form="FlagIssue"]').removeClass("form-success");
    $('[data-form="FlagIssue"]').find("input[type='text']").val("");
    $('[data-form="FlagIssue"]').find("input[type='file']").val("");
    $('[data-form="FlagIssue"]').find("textarea").val("");
    $("#buttonMessage").show();
    $("#buttonAttachMessage").hide();
    $('input[type="radio"]').prop("checked", false);
    grecaptcha.reset();

    $("#AttachmentsDiv").html("");
  };

  closePopup = () => {
    this.favtrigger.removeClass("active");
    this.searchtrigger.removeClass("active");

    this.searchpop.removeClass("active");
    this.favpop.removeClass("active");

    this.htmlBody.removeClass("popup-open favorite search");
  };

  closeMenu = () => {
    this.menu.removeClass("active");

    this.menuslide.removeClass("active");
    setTimeout(() => {
      this.htmlBody.removeClass("menu-open");
    }, 400);
  };

  closeFlagIssue = () => {
    this.flagissue.removeClass("active");

    this.flaganissue.removeClass("active");
    setTimeout(() => {
      this.htmlBody.removeClass("menu-open");
    }, 400);
  };

  heightCalculate = () => {
    if (this.isMobile == false || this.ie == false) {
      this.divHeight = $(".what-we-do");
      this.bannerHeight = $(".home-banner");
      this.screenWidth = $(window).width();
      let screenHeight = $(window).height();
      let maxHeight = this.divHeight.outerHeight();
      let bannerheight = this.bannerHeight.outerHeight();
      let sectionHeight;
      let heightDifference;
      let calculatedHeight;

      let halfHeight;
      halfHeight = (screenHeight * 60) / 100;

      if (maxHeight >= halfHeight) {
        sectionHeight = maxHeight;

        calculatedHeight = sectionHeight + bannerheight;

        if (calculatedHeight < screenHeight) {
          heightDifference = screenHeight - calculatedHeight;
          sectionHeight = sectionHeight + heightDifference;
          // sectionHeight = (sectionHeight/screenHeight)*100;
          // bannerheight = 100 - (sectionHeight/screenHeight)*100;
          // this.bannerHeight.css("height",  bannerheight + '%');
          $(".home-body").css("height", sectionHeight + "px");
        } else {
          $(".home-body").css("height", sectionHeight + "px");
        }
      } else {
        sectionHeight = halfHeight;

        calculatedHeight = sectionHeight + bannerheight;
        console.log("sectionHeight -----:" + sectionHeight);
        console.log("banner-geight:" + bannerheight);
        console.log("new full hieght:" + calculatedHeight);
        console.log("screen hieght:" + screenHeight);

        if (calculatedHeight < screenHeight) {
          heightDifference = screenHeight - calculatedHeight;
          sectionHeight = sectionHeight + heightDifference;

          console.log("final section hieght:" + sectionHeight);
          // this.bannerHeight.css("height",  bannerheight + '%');
          $(".home-body").css("height", sectionHeight + "px");
        } else {
          $(".home-body").css("height", sectionHeight + "px");
        }
      }
    }
  };

  windowResize = () => {
    let screenHeight = $(window).height();
    let screenWidth = $(window).width();
    if (this.ie == false) {
      if ($(".home-page").length) {
        if (screenHeight < 718 || screenWidth < 1200) {
          this.destroyTilt();
        } else {
          this.tiltTrigger();
        }
        // this.heightCalculate();
        // this.homeSliders();
      }
    }
  };

  windowScroll = () => {
    let screenHeight = $(window).height();
    let scrollTop = $(window).scrollTop();

    $("#gotoTop").toggleClass("active", scrollTop > screenHeight / 2);
  };

  handleUserAgent = () => {
    // detect mobile platform
    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
      this.htmlBody.addClass("ios-device");
    }
    if (navigator.userAgent.match(/Android/i)) {
      this.htmlBody.addClass("android-device");
    }

    // detect desktop platform
    if (navigator.appVersion.indexOf("Win") !== -1) {
      this.htmlBody.addClass("win-os");
    }
    if (navigator.appVersion.indexOf("Mac") !== -1) {
      this.htmlBody.addClass("mac-os");
    }

    // detect IE 10 and 11P
    if (
      navigator.userAgent.indexOf("MSIE") !== -1 ||
      navigator.appVersion.indexOf("Trident/") > 0
    ) {
      this.html.addClass("ie10");
    }

    // detect IE Edge
    if (/Edge\/\d./i.test(navigator.userAgent)) {
      this.html.addClass("ieEdge");
    }

    // Specifically for IE8 (for replacing svg with png images

    // show ie overlay popup for incompatible browser
    if (this.html.hasClass("ie9")) {
      const message = $(
        '<div class="no-support"> You are using outdated browser. Please <a href="https://browsehappy.com/" target="_blank">update</a> your browser or <a href="https://browsehappy.com/" target="_blank">install</a> modern browser like Google Chrome or Firefox.<div>'
      );
      this.htmlBody.prepend(message);
    }
  };
})();
