export const mapStyle = [
  {
    "featureType": "all",
    "stylers": [
      {
        "saturation": 0
      },
      {
        "hue": "#e7ecf0"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "saturation": -70
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "visibility": "simplified"
      },
      {
        "saturation": -60
      }
    ]
  }
];

export const mapOptions = google => ({
  zoom: 15,
  zoomControl: true,
  zoomControlOptions: {
    position: google.ControlPosition.RIGHT_CENTER,
    style: google.ZoomControlStyle.SMALL,
  },
  scaleControl: true,
  scrollwheel: false,
  mapTypeId: google.MapTypeId.ROADMAP, // ROADMAP or SATELLITE
  panControl: true,
  mapTypeControl: false,
  panControlOptions: {
    position: google.ControlPosition.RIGHT_CENTER,
  },
  streetViewControl: false,
});
