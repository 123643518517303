import loadGoogleMapsApi from 'load-google-maps-api';
import { mapStyle, mapOptions } from './constants';

export default class Maps {
  constructor() {
    this.mapContainer = document.getElementById('map_canvas');
    this.lat = '25.2206287';
    this.long = '55.2859869';

    this.siteOrigin = window.location.origin;

    if (this.mapContainer) {
      loadGoogleMapsApi({
        key: 'AIzaSyDclqYsBUh7dPdIWFLpZcpZ3SwTfPpnODk',
      }).then((google) => {
        this.google = google;
        console.log(google);
        this.init(google);
      });
    }
  }

  init = (google) => {

    this.myLatlng = new google.LatLng(this.lat,this.long);
    this.mapCenter = new google.LatLng(this.lat,this.long);
    this.directionURL = 'https://www.google.com/maps/dir//Financial+Audit+Authority+Dubai+-+Trade+CentreTrade+Centre+2+-+Dubai+-+United+Arab+Emirates/@'+this.lat+','+this.long+',17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x3e5f437c2c712459:0x65fa797ba7ec299d!2m2!1d55.2859869!2d25.2206287!3e0';
    const markerPos = {

      url: this.siteOrigin+'/Style Library/FAA/design/dist/images/marker.svg',
      size: new google.Size(68 , 68),
      anchor: new google.Point(34, 34),
      scaledSize: new google.Size(68, 68)
    };

    this.mapIconUrl = this.siteOrigin+'/Style Library/FAA/design/dist/images/marker.svg';
    this.map = new google.Map(this.mapContainer, {
      ...mapOptions(google),
      ...{
        center: this.mapCenter,
        styles: mapStyle,
      },
    });

    this.infowindow = new this.google.InfoWindow({});


    this.marker = new google.Marker({
      position: this.myLatlng,
      map: this.map,
      animation: google.Animation.DROP,
      icon: markerPos,
      title: 'Maps',
    });


    this.bindEvents();
  };

  bindEvents = () => {
    this.google.event.addListener(this.marker, 'click', this.toggleBounce);
  };

  toggleBounce = (markerClicked) => {
    window.open(this.directionURL, '_blank');
  };
}
